<template>
  <v-layout wrap fill-height>
    <v-flex
      xs12
      pt-0 pb-0
    >
      <legal-page-training-corner-card>
      </legal-page-training-corner-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex'
import LegalPageTrainingCornerCard from "@/components/app/legal-page/training-corner-card";

export default {
  components: {
    LegalPageTrainingCornerCard
  },
  methods: {
    ...mapActions('app', ['setMainTitle'])
  },
  created () {
    this.setMainTitle('Legal Training Corner')
  }
}
</script>

<style>

</style>
